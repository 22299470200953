<template>
  <div
    class="washroomarchives flex-col item-b"
    v-loading="showLoad"
    element-loading-text="拼命加载中"
  >
    <!-- 查询控制 -->
    <div class="header">
      <div class="search-box">
        <el-input
          class="custom-input"
          v-model="searchText"
          placeholder="请输入公厕名称"
        ></el-input>
      </div>
      <el-button class="btn item-primary" type="primary" @click="searchBtn"
        >查询</el-button
      >
      <el-button
        class="btn item-warning"
        :disabled="operShow"
        type="warning"
        @click="addBtn"
        >新增</el-button
      >
      <el-button class="btn item-info" type="info" @click="resetBtn"
        >重置</el-button
      >
    </div>

    <!-- 表格内容 -->
    <div class="content">
      <el-table
        :data="dataList"
        stripe
        :header-cell-style="tableHeaderColor"
        :cell-style="rowClass"
      >
        <el-table-column
          align="center"
          width="60"
          label="序号"
          type="index"
        ></el-table-column>
        <el-table-column
          align="center"
          label="公厕名称"
          prop="toiletName"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          align="center"
          label="管养单位"
          prop="managementDeptName"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          label="服务类型"
          prop="serveName"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          align="center"
          label="管养项目部"
          prop="projectDeptName"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          label="联系人"
          prop="staffName"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          align="center"
          label="联系电话"
          prop="staffPhone"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column align="center" label="经纬度" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.longitude + "," + scope.row.latitude }}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="地址"
          prop="location"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          align="center"
          label="男厕设备地址码"
          prop="manCode"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          align="center"
          label="女厕设备地址码"
          prop="womanCode"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          label="公厕使用状态"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span v-if="scope.row.workStatus == 1"> 正常</span>
            <span v-if="scope.row.workStatus == 2"> 关闭</span>
            <span v-if="scope.row.workStatus == 3"> 报修</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" min-width="150px">
          <template slot-scope="scope">
            <div class="flex btnbox">
              <el-button
                plain
                type="primary"
                :disabled="operShow"
                size="small"
                @click="editBtn(scope)"
                >编辑</el-button
              >
              <el-button
                plain
                type="danger"
                :disabled="operShow"
                size="small"
                @click="removeBtn(scope)"
                >删除</el-button
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页器 -->
      <div class="c-center page-control">
        <el-pagination
          :total="total"
          background
          layout="total,prev, pager, next, jumper"
          @current-change="pageChange"
          :current-page.sync="curIndex"
        ></el-pagination>
      </div>
    </div>
    <!-- 新增公厕 -->
    <el-drawer
      custom-class="drawer-class"
      :visible.sync="show"
      :show-close="false"
      :withHeader="false"
      :wrapperClosable="false"
    >
      <div class="drawer-box flex-col">
        <div class="title">新增公厕</div>
        <div class="table auto">
          <el-form
            :model="addWRData"
            size="small"
            :rules="addWRDataRule"
            label-position="left"
            label-width="auto"
            ref="addForm"
            :hide-required-asterisk="true"
          >
            <el-form-item label="公厕名称" prop="toiletName">
              <el-input
                placeholder="请输入公厕名称"
                v-model="addWRData.toiletName"
              ></el-input>
            </el-form-item>
            <el-form-item label="管养单位">
              <el-input
                placeholder="管养单位"
                v-model="addWRData.managementDeptName"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="管养项目部" prop="project">
              <el-select
                v-model="addWRData.project"
                placeholder="请选择管养项目部"
              >
                <el-option
                  v-for="item in projectList"
                  :label="item.deptName"
                  :value="item.id"
                  :key="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="服务类型" prop="serveName">
              <el-input
                placeholder="请输入服务类型"
                v-model="addWRData.serveName"
              ></el-input>
            </el-form-item>
            <el-form-item label="联系人" prop="staff">
              <el-select
                v-model="addWRData.staff"
                placeholder="请选择联系人"
                @change="changePhone"
              >
                <el-option
                  v-for="item in staffList"
                  :label="item.staffName"
                  :value="item.id"
                  :key="item.id"
                ></el-option>
              </el-select>
              <!-- <el-input placeholder="联系人" v-model="addWRData.name" disabled></el-input> -->
            </el-form-item>
            <el-form-item label="联系电话" prop="phone">
              <el-input
                placeholder="请输入联系电话"
                v-model="addWRData.phoneRen"
              ></el-input>
            </el-form-item>
            <el-form-item label="男厕设备地址码" prop="manCode">
              <el-input
                placeholder="请输入男厕设备地址码"
                v-model="addWRData.manCode"
              ></el-input>
            </el-form-item>
            <el-form-item label="女厕设备地址码" prop="womanCode">
              <el-input
                placeholder="请输入女厕设备地址码"
                v-model="addWRData.womanCode"
              ></el-input>
            </el-form-item>

            <el-form-item label="地址" prop="addr">
              <el-input
                placeholder="请输入地址"
                v-model="addWRData.addr"
              ></el-input>
            </el-form-item>
            <el-form-item label="经纬度" prop="position">
              <el-input
                placeholder="请输入经纬度"
                v-model="addWRData.position"
                @focus="showMap = true"
              >
              </el-input>
            </el-form-item>
          </el-form>
        </div>
        <div class="c-center btn">
          <el-button type="primary submit-primary" @click="saveAdd"
            >保存</el-button
          >
          <el-button type="info submit-info" @click="cancelAdd">取消</el-button>
        </div>
      </div>
    </el-drawer>

    <!-- 编辑公厕 -->
    <el-drawer
      custom-class="drawer-class"
      :visible.sync="showEdit"
      :show-close="false"
      :withHeader="false"
      :wrapperClosable="false"
    >
      <div class="drawer-box flex-col">
        <div class="title">编辑公厕</div>
        <div class="table auto">
          <el-form
            :model="editWRData"
            size="small"
            :rules="addWRDataRule"
            label-position="left"
            label-width="auto"
            ref="editForm"
            :hide-required-asterisk="true"
          >
            <el-form-item label="男厕设备地址码">
              <el-input
                placeholder="请输入男厕设备地址码"
                v-model="editWRData.manCode"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="女厕设备地址码">
              <el-input
                placeholder="请输入女厕设备地址码"
                v-model="editWRData.womanCode"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="公厕名称" prop="toiletName">
              <el-input
                placeholder="请输入公厕名称"
                v-model="editWRData.toiletName"
              ></el-input>
            </el-form-item>
            <el-form-item label="管养单位" prop="managementDeptName">
              <el-input
                placeholder="管养单位"
                v-model="editWRData.managementDeptName"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="管养项目部" prop="project">
              <el-select
                v-model="editWRData.project"
                placeholder="请选择管养项目部"
              >
                <el-option
                  v-for="item in projectList"
                  :label="item.deptName"
                  :value="item.id"
                  :key="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="服务类型" prop="serveName">
              <el-input
                placeholder="请输入服务类型"
                v-model="editWRData.serveName"
              ></el-input>
            </el-form-item>
            <el-form-item label="联系人" prop="staff">
              <el-select v-model="editWRData.staff" placeholder="请选择联系人">
                <el-option
                  v-for="item in staffList"
                  :label="item.staffName"
                  :value="item.id"
                  :key="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="联系电话" prop="phone">
              <el-input
                placeholder="请输入联系电话"
                v-model="editWRData.phone"
              ></el-input>
            </el-form-item>

            <el-form-item label="地址" prop="addr">
              <el-input
                placeholder="请输入地址"
                v-model="editWRData.addr"
              ></el-input>
            </el-form-item>
            <el-form-item label="经纬度" prop="position">
              <el-input
                placeholder="请输入经纬度"
                v-model="editWRData.position"
                @focus="showMap = true"
              >
              </el-input>
            </el-form-item>
            <el-form-item label="是否使用" prop="staff">
              <el-select
                v-model="editWRData.workStatus"
                :disabled="editWRData.workStatus == 3"
                placeholder="请选择使用情况"
              >
                <el-option
                  :disabled="item.value == 3"
                  v-for="item in UsageList"
                  :label="item.label"
                  :value="item.value"
                  :key="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </div>
        <div class="c-center btn">
          <el-button type="primary submit-primary" @click="saveUpdate"
            >保存</el-button
          >
          <el-button type="info submit-info" @click="cancelEdit"
            >取消</el-button
          >
        </div>
      </div>
    </el-drawer>

    <!-- 选择地图经纬度 -->
    <el-dialog
      :visible.sync="showMap"
      @opened="openMap"
      :center="true"
      title="点击地图选择经纬度"
    >
      <div id="selectPos"></div>
    </el-dialog>
  </div>
</template>

<script>
import axios from "../request";
import { tableHeaderColor, rowClass } from "@/utils/table";
export default {
  name: "WashroomArchives",
  data() {
    return {
      operShow: false,
      map: null,
      show: false,
      showEdit: false,
      showMap: false,
      showLoad: true,
      curIndex: 1,
      searchText: "",
      total: 0,
      projectList: [], //项目部列表
      dept: null, //管养单位
      staffList: [], //联系人列表
      UsageList: [
        {
          value: 1,
          label: "正常使用",
        },
        {
          value: 2,
          label: "暂停使用",
        },
        {
          value: 3,
          label: "报修",
        },
      ],
      dataList: [],
      shifou: "",
      // 增加公厕的数据字段
      addWRData: {
        toiletName: "", //公厕名称
        managementDeptName: "", //管养单位
        project: "", //管养项目部
        serveName: "", //服务类型
        staff: "", //联系人
        phoneRen: "", //联系电话
        manCode: "", //男厕设备地址码
        womanCode: "", //女厕设备地址码
        addr: "", //地址
        position: "", //经纬度
      },
      // 编辑公厕的数据字段
      editWRData: {
        toiletName: "", //公厕名称
        managementDeptName: "", //管养单位
        project: "", //管养项目部 //
        serveName: "", //服务类型
        staff: "", //联系人 //
        phone: "", //联系电话 //
        manCode: "", //男厕设备地址码
        womanCode: "", //女厕设备地址码
        addr: "", //地址
        position: "", //经纬度
        id: "",
        workStatus: "", // 使用情况
      },
      // 增加公厕的校验规则
      addWRDataRule: {
        toiletName: [
          { required: true, message: "请输入公厕名称" },
          { max: 20, message: "最多20个字符" },
          {
            pattern: /^[\u4e00-\u9fa5a-zA-Z]*$/,
            message: "不能包含特殊符号",
            trigger: "blur",
          },
        ],
        serveName: [
          { required: true, message: "请输入服务类型" },
          { max: 20, message: "最多20个字符" },
          {
            pattern: /^[\u4e00-\u9fa5]*$/,
            message: "不能包含特殊符号",
            trigger: "blur",
          },
        ],
        project: [{ required: true, message: "请选择管养项目部" }],
        staff: [{ required: true, message: "请选择联系人" }],
        phoneRen: [
          { required: true, message: "请输入联系电话" },
          {
            tyep: "regexp",
            pattern: /^1[0-9]{10}$/,
            message: "请输入正确的联系电话",
          },
        ],
        position: [{ required: true, message: "请选择经纬度" },{
            pattern: /^(-?180(\.0{1,6})?|0(\.0{1,6})?|(-?([1-9]|[1-9][0-9]|1[0-7][0-9])(\.\d{1,6})?)),(-?90(\.0{1,6})?|0(\.0{1,6})?|(-?([1-8]?[0-9])(\.\d{1,6})?))$/,
            message: "请输入正确的经纬度",
            trigger: "blur",
          }],
        addr: [
          { required: true, message: "请输入地址" },
          { max: 50, message: "最多50个字符" },
          {
            pattern: /^[\u4e00-\u9fa5a-zA-Z0-9]+$/,
            message: "不能包含特殊符号",
            trigger: "blur",
          },
        ],
        manCode: [
          { required: true, message: "请输入设备地址码" },
          {
            pattern: /^[a-zA-Z0-9]+$/,
            message: "不能包含特殊符号",
            trigger: "blur",
          },
        ],
        womanCode: [
          { required: true, message: "请输入设备地址码" },
          {
            pattern: /^[a-zA-Z0-9]+$/,
            message: "不能包含特殊符号",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    tableHeaderColor({ row, rowIndex }) {
      return tableHeaderColor();
    },
    rowClass({ row, rowIndex }) {
      return rowClass();
    },

    cancelAdd() {
      this.$refs.addForm.resetFields();
      this.show = false;
    },
    cancelEdit() {
      this.$refs.editForm.resetFields();
      this.showEdit = false;
    },
    changePhone(val) {
      let phones = this.staffList.find((res) => {
        if (val == res.id) {
          return res.phone;
        }
      });
      this.addWRData.phoneRen = phones.phone;
    },
    // 提交新增
    saveAdd() {
      console.log(this.addWRData);
      this.$refs.addForm.validate(async (valid) => {
        if (valid) {
          const params = {
            // 公厕名称
            toiletName: this.addWRData.toiletName,
            // 管养单位id
            managementDeptId: this.dept.id,
            // 管养单位名称
            managementDeptName: this.addWRData.managementDeptName,
            // 地址
            location: this.addWRData.addr,
            // 管养项目部id
            projectDeptId: this.addWRData.projectDeptId,
            // 联系人id
            staffId: this.addWRData.staff,
            // 经度
            longitude: this.addWRData.position.split(",")[0],
            // 纬度
            latitude: this.addWRData.position.split(",")[1],
            // 男厕设备地址码
            manCode: this.addWRData.manCode,
            // 女厕设备地址码
            womanCode: this.addWRData.womanCode,
            // 服务类型
            serveName: this.addWRData.serveName,
            // 联系电话
            staffPhone: this.addWRData.phone,
          };
          let data = await axios.post("/toilet/add", params);
          if (data === null) {
            this.cancelAdd();
            this.resetBtn();
            this.$message({
              message: "添加成功！",
              type: "success",
            });
            this.$router.go(0);
          }
        }
      });
    },
    // 提交编辑
    saveUpdate() {
      console.log(this.editWRData);
      this.$refs.editForm.validate(async (valid) => {
        console.log(valid);
        if (valid) {
          const params = {
            // 公厕名称
            toiletName: this.editWRData.toiletName,
            // 管养单位id
            managementDeptId: this.dept.id,
            // 管养单位
            managementDeptName: this.editWRData.managementDeptName,
            // 地址
            location: this.editWRData.addr,
            // 项目部id
            projectDeptId: this.editWRData.projectDeptId,
            // 人员id
            staffId: this.editWRData.staff,
            // 经度
            longitude: this.editWRData.position.split(",")[0],
            // 纬度
            latitude: this.editWRData.position.split(",")[1],
            // 女厕设备地址码
            womanCode: this.editWRData.womanCode,
            // 男厕设备地址码
            manCode: this.editWRData.manCode,
            // 服务类型
            serveName: this.editWRData.serveName,
            id: this.editWRData.id,
            workStatus: this.editWRData.workStatus,
          };
          let data = await axios.post("/toilet/update", params);
          if (data === null) {
            this.cancelEdit();
            this.resetBtn();
            this.$message({
              message: "编辑成功！",
              type: "success",
            });
          }
        }
      });
    },
    async pageChange(index = 1) {
      this.showLoad = true;
      let data = await axios.get(`/toilet/page/${index}/10`, {
        params: { toiletName: this.searchText },
      });

      if (data) {
        this.total = data.total;
        this.dataList = data.list;
        this.showLoad = false;
      }
    },
    searchBtn() {
      if (this.searchText === "") return;
      this.pageChange();
    },
    resetBtn() {
      this.searchText = "";
      this.curIndex = 1;
      this.pageChange();
    },
    openMap() {
      if (!this.map) {
        const that = this;
        const point = new BMap.Point(117.584723, 37.650997); // 创建点坐标
        this.map = new window.BMap.Map("selectPos");
        this.map.centerAndZoom(point, 13);
        this.map.enableScrollWheelZoom(true);
        this.map.addEventListener("click", function (e) {
          that.addWRData.longitude = e.point.lng;
          that.addWRData.latitude = e.point.lat;
          that.addWRData.position = e.point.lng + "," + e.point.lat;
          that.editWRData.position = e.point.lng + "," + e.point.lat;
          that.showMap = false;
        });
        var bdary = new BMap.Boundary();
        bdary.get("东营区", function (rs) {
          //获取行政区域
          // Thit.map.clearOverlays(); //清除地图覆盖物
          var count = rs.boundaries.length; //行政区域的点有多少个
          for (var i = 0; i < count; i++) {
            var ply = new BMap.Polygon(rs.boundaries[i], {
              strokeWeight: 2,
              fillColor: "#8BFFCC", //设置多边形填充颜色
              strokeColor: "#259E50",
              fillOpacity: 0.2,
            }); //建立多边形覆盖物

            that.map.addOverlay(ply); //添加覆盖物

            that.map.setViewport(ply.getPath()); //调整视野
          }
        });
      }
    },
    removeBtn({ row }) {
      this.$confirm("此操作将永久删除此公厕, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let data = await axios.post(`/toilet/deleteById/${row.id}`);
          if (data === null) {
            // this.resetBtn()
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.$router.go(0);
          }
        })
        .catch(() => {});
    },
    // 编辑按钮
    editBtn(scope) {
      console.log(scope);
      this.getData();
      this.showEdit = true;
      this.editWRData.toiletName = scope.row.toiletName;
      this.editWRData.managementDeptName = scope.row.managementDeptName;
      this.editWRData.project = scope.row.projectDeptName;
      this.editWRData.serveName = scope.row.serveName;
      this.editWRData.staff = scope.row.staffId;
      this.editWRData.phone = scope.row.staffPhone;
      this.editWRData.manCode = scope.row.manCode;
      this.editWRData.womanCode = scope.row.womanCode;
      this.editWRData.addr = scope.row.location;
      this.editWRData.position = scope.row.longitude + "," + scope.row.latitude;
      this.editWRData.id = scope.row.id;
      this.editWRData.workStatus = scope.row.workStatus;
      if (this.editWRData.workStatus !== 3) {
        this.shifou = true;
      }
    },
    addBtn() {
      this.getData();
      this.show = true;
    },
    async getData() {
      // 获取管养项目部列表
      if (this.projectList.length < 1) {
        let data = await axios.get("/projectDept/findAll");
        if (data) {
          this.projectList = data;
        }
      }
      // 获取管养单位
      if (!this.dept) {
        let data = await axios.get("/sysDict/findByCode", {
          params: { code: "management_dept" },
        });
        if (data) {
          this.dept = data;
          this.addWRData.managementDeptName = data.dataValue;
        }
      }
      // 获取联系人列表
      if (this.staffList.length < 1) {
        let data = await axios.get("/deptStaff/findAll");
        if (data) {
          this.staffList = data;
        }
      }
    },
  },
  created() {
    if (sessionStorage.getItem("username") == "yanshi") {
      this.operShow = true;
    }
    this.pageChange();
  },
};
</script>

<style scoped lang="scss">
.washroomarchives {
  flex: auto;
  height: 100%;
  box-sizing: border-box;

  & ::v-deep .el-drawer__body {
    overflow: auto;
  }
}

.header {
  flex: none;
  display: flex;
  padding: 20px;

  .search-box {
    width: 12rem;
    margin-right: 10px;
  }

  .btn {
    border: none;
    font-weight: 500;
  }
}

.content {
  overflow: auto;
  flex: auto;
  width: calc(100% - 8px);
}

.page-control {
  position: relative;
  top: 5%;
}

.drawer-box {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  overflow: auto;

  .title {
    padding: 20px;
    font-size: 1.2rem;
    font-weight: bold;
    text-align: center;
    border-bottom: solid 1px #dadada;
  }

  .table {
    padding: 20px;
    overflow: auto;
  }

  .btn {
    padding: 30px 0;
  }
}

.drawer-class ::v-deep .el-drawer__body {
  overflow: auto;
}

.btnbox {
  margin: 0 auto;
  width: 132px;
}

.drawer-box ::v-deep .el-select {
  width: 100%;
}

#selectPos {
  width: 100%;
  height: 400px;
}

.el-button--danger.is-plain {
  background: white;
}

.el-button--danger.is-plain:focus {
  background: white;
  color: #f56c6c;
}

.el-button--danger.is-plain:hover {
  background: #f56c6c;
  border-color: #f56c6c;
  color: #fff;
}

.el-button--danger.is-plain:active {
  background: white;
  color: #f56c6c;
}

.el-button--primary.is-plain {
  background: white;
}

.el-button--primary.is-plain:focus {
  color: #409eff;
  background: white;
}

.el-button--primary.is-plain:hover {
  color: #fff;
  background: #409eff;
  border-color: #409eff;
}

.el-button--primary.is-plain:active {
  color: #409eff;
  background: white;
  //border-color: #b3d8ff;
}

::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #3b7fff;
}

@media screen and (max-height: 1080px) {
  ::v-deep .page-control {
    text-align: center;
    margin: 4% 0px;
  }
}

@media screen and (max-height: 970px) {
  ::v-deep .page-control {
    text-align: center;
    margin: 1% 0px;
  }
}
</style>
